/* eslint-disable jsx-a11y/role-supports-aria-props */
import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Icon } from '../../../_shared';

type TProps = {
  isLanguageMenuOpen: boolean;
  toggleLanguageMenu: (nextValue?: boolean) => void;
};

export const LanguageList: FC<TProps> = ({ toggleLanguageMenu }) => {
  const { t } = useTranslation();

  return (
    <div
      aria-labelledby="ecl-language-list__title"
      className="ecl-site-header__language-container"
      data-ecl-language-list-overlay
      hidden
      id="language-list-overlay"
      role="dialog"
    >
      <div className="ecl-site-header__language-header">
        <div className="ecl-site-header__language-title" id="ecl-site-header__language-title">
          Select your language
        </div>
        <button
          className="ecl-button ecl-button--tertiary ecl-site-header__language-close ecl-button--icon-only"
          data-ecl-language-list-close
          type="submit"
        >
          <span className="ecl-button__container">
            <span className="ecl-button__label" data-ecl-label="true">
              Close
            </span>
            <Icon className="ecl-site-header-core__icon" name="close" size="m" />
          </span>
        </button>
      </div>
      <div className="ecl-site-header__language-content">
        <div className="ecl-site-header__language-category" data-ecl-language-list-eu>
          <div className="ecl-site-header__language-category-title">Official EU languages:</div>
          <ul className="ecl-site-header__language-list">
            <li className="ecl-site-header__language-item">
              <a
                className="ecl-link ecl-link--standalone ecl-link--no-visited ecl-site-header__language-link ecl-site-header__language-link--active"
                href="/component-library/example#mcnon"
                hrefLang="en"
                lang="en"
              >
                <span className="ecl-site-header__language-link-code">en</span>
                <span className="ecl-site-header__language-link-label">English</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ecl-language-list__container ecl-container">
        <div className="ecl-row">
          <div className="ecl-language-list__close ecl-col-12 ecl-col-l-8 ecl-offset-l-2">
            <Button
              className="ecl-language-list__close-button"
              icon="close"
              iconPosition="after"
              iconSize="s"
              onClick={() => {
                toggleLanguageMenu(false);
              }}
              theme="ghost"
            >
              {t('SHARED.BUTTONS.CLOSE')}
            </Button>
          </div>
          <div className="ecl-language-list__title ecl-col-12 ecl-col-l-8 ecl-offset-l-2" id="ecl-language-list__title">
            <Icon className="ecl-language-list__title-icon" name="generic-lang" size="m" />
            {t('SITE_HEADER.SELECT_LANGUAGE')}
          </div>
        </div>
        <div className="ecl-row ecl-language-list__eu">
          <div className="ecl-language-list__category ecl-col-12 ecl-col-l-8 ecl-offset-l-2">{t('SITE_HEADER.EU_LANGUAGES')}</div>
          <div className="ecl-language-list__column ecl-col-12 ecl-col-l-4 ecl-offset-l-2">
            <ul className="ecl-language-list__list">
              <li className="ecl-language-list__item ecl-language-list__item--is-active">
                <a
                  className="ecl-link ecl-link--standalone ecl-link--icon ecl-link--icon-after ecl-language-list__link"
                  href="/component-library/example"
                  hrefLang={t('SHARED.LOCALE.EN_CODE')}
                  lang={t('SHARED.LOCALE.EN_CODE')}
                  rel="alternate"
                >
                  <span className="ecl-link__label">{t('SHARED.LOCALE.EN')}</span>
                  <Icon className="ecl-link__icon" name="check" size="xs" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
