import { FC } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { getUserName } from '../../../_models';
import { Button, Icon } from '../../../_shared';
import { useAuthContext } from '../../../auth/_context';
import { useLogout } from '../../../auth/_queries';
import './login.scss';

type TProps = {
  isLoginMenuOpen: boolean;
  toggleLoginMenu: () => void;
};

export const Login: FC<TProps> = ({ isLoginMenuOpen, toggleLoginMenu }) => {
  const { t } = useTranslation();
  const { isLoggedIn, profile, loginRedirectUrl } = useAuthContext();
  const { mutate: logout } = useLogout();

  function handleLogout() {
    logout();
    toggleLoginMenu();
  }

  return (
    <div className="ecl-site-header__login-container">
      {isLoggedIn ? (
        <>
          <button
            aria-controls="login-box-id"
            aria-expanded={isLoginMenuOpen}
            className="ecl-button ecl-button--tertiary ecl-site-header__login-toggle"
            data-ecl-login-toggle
            onClick={toggleLoginMenu}
          >
            <Icon className="ecl-site-header__icon" name="logged-in" size="s" />
            Logged in
          </button>
          <div
            className={classnames('ecl-site-header__login-box', {
              'ecl-site-header__login-box--active': isLoginMenuOpen,
            })}
            data-ecl-login-box
            id="login-box-id"
          >
            <p className="ecl-site-header__login-description">{t('SITE_HEADER.LOGGED_IN_AS', { name: getUserName(profile) })}</p>
            <hr className="ecl-site-header-__login-separator" />
            <Button className="my-profile" href="profile" onClick={toggleLoginMenu} theme="ghost">
              {t('SITE_HEADER.MY_PROFILE')}
            </Button>
            <hr className="ecl-site-header__login-separator" />
            <Button className="logout" onClick={handleLogout} theme="ghost">
              {t('SHARED.LOG_OUT')}
            </Button>
          </div>
        </>
      ) : (
        <a
          className="ecl-button ecl-button--tertiary ecl-site-header__login-toggle"
          data-ecl-login-toggle
          href={loginRedirectUrl}
        >
          <Icon className="ecl-site-header__icon" name="log-in" size="s" />
          {t('SHARED.LOG_IN')}
        </a>
      )}
    </div>
  );
};
