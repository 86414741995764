import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useToggle } from '../../_hooks';
import { Icon } from '../../_shared';
import { DATE_MONTH_FORMAT, formatISOString, TIME_STRING_FORMAT } from '../../_utils/dateHelpers';

import CardLabel, { CardLabelType } from './components/CardLabel';
import CardTag from './components/CardTag';
import './card.scss';

/**
 * EC Component: Card
 * html: https://ec.europa.eu/component-library/playground/ec/?path=/story/components-card--card
 * js: No js
 */

type TProps = {
  date: string;
  image: string;
  imageAlt?: string;
  labelType?: CardLabelType;
  link: string;
  tags: string[];
  title: string;
};

const Card: FC<TProps> = ({ image, imageAlt = '', link, title, tags, date, labelType }) => {
  const { t } = useTranslation();
  const [areAllTagsVisible, toggleTagsVisibility] = useToggle(false);

  return (
    <article className="ecl-card">
      <picture className="ecl-picture ecl-card__picture" data-ecl-picture-link>
        <img alt={imageAlt} className="ecl-card__image" src={image} />
      </picture>
      <div className="ecl-card__body">
        {labelType && (
          <div className="ecl-card__label-container">
            <CardLabel type={labelType} />
          </div>
        )}
        <div className="ecl-content-block__title" data-ecl-title-link>
          <Link className="ecl-link ecl-link--standalone" to={link}>
            {title}
          </Link>
        </div>
        <ul className="ecl-card__info-container">
          <li className="ecl-card__info-item">
            <Icon name="calendar" size="xs" />
            <span className="ecl-card__info-label">{formatISOString(date, DATE_MONTH_FORMAT)}</span>
          </li>
          <li className="ecl-card__info-item">
            <Icon name="SvgClock" size="xs" />
            <span className="ecl-card__info-label">{formatISOString(date, TIME_STRING_FORMAT)}</span>
          </li>
        </ul>
        <ul className="ecl-card__tag-container">
          {tags.slice(0, areAllTagsVisible ? tags.length : 3).map(tag => (
            <li className="ecl-card__tag-container__tag-item" key={tag}>
              <CardTag tag={tag} />
            </li>
          ))}
          {tags.length > 3 ? (
            <li className="ecl-card__tag-container__tag-item">
              <button className="ecl-button ecl-button--ghost ecl-button--xs" onClick={toggleTagsVisibility}>
                {areAllTagsVisible ? t('SHARED.BUTTONS.SHOW_LESS') : t('SHARED.BUTTONS.SHOW_MORE')}
              </button>
            </li>
          ) : null}
        </ul>
      </div>
    </article>
  );
};

export default Card;
