import { FC, ReactNode, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Icon } from '../../_shared';

import 'swiper/css/bundle';
import 'swiper/css';

import './swiperCarousel.scss';

type TProps = {
  emptyLabel: string;
  navigationTheme?: 'default' | 'minimal';
  slides: ReactNode[];
  slidesPerView?: number;
  spaceBetween?: number;
};

const SwiperCarousel: FC<TProps> = ({ slides, spaceBetween = 0, slidesPerView = 1, navigationTheme = 'default', emptyLabel }) => {
  const { t } = useTranslation();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const isMinimalNavStyling = navigationTheme === 'minimal';

  return (
    <div className="swiper-carousel">
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={{ nextEl: navigationNextRef.current, prevEl: navigationPrevRef.current }}
        onBeforeInit={swiper => {
          swiper.params.navigation;
          swiper.params.navigation['prevEl'] = navigationPrevRef.current;
          swiper.params.navigation['nextEl'] = navigationNextRef.current;
        }}
        pagination={{ clickable: true, dynamicBullets: true }}
        slidesPerGroup={4}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
      >
        {(!slides || slides.length === 0) && (
          <div className="swiper-carousel__empty">
            <span>{emptyLabel}</span>
          </div>
        )}
        {slides.map((slide, i) => (
          <SwiperSlide key={`slide-${i}`}>{slide}</SwiperSlide>
        ))}
        <div className="ecl-carousel__controls">
          <button
            className={classNames('ecl-button ecl-button--tertiary ecl-button--icon-only ecl-carousel__prev', {
              'ecl-carousel__prev--minimal': isMinimalNavStyling,
            })}
            ref={navigationPrevRef}
          >
            <span className="ecl-button__container">
              <span className="ecl-button__label">{t('SHARED.CAROUSEL.PREVIOUS')}</span>
              <Icon name="corner-arrow" size="m" transformation="rotate-270" />
            </span>
          </button>
          <button
            className={classNames('ecl-button ecl-button--tertiary ecl-button--icon-only ecl-carousel__next', {
              'ecl-carousel__next--minimal': isMinimalNavStyling,
            })}
            ref={navigationNextRef}
          >
            <span className="ecl-button__container">
              <span className="ecl-u-sr-only">{t('SHARED.CAROUSEL.NEXT')}</span>
              <Icon name="corner-arrow" size="m" transformation="rotate-90" />
            </span>
          </button>
        </div>
      </Swiper>
    </div>
  );
};

export default SwiperCarousel;
