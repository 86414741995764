export const ecIconsSocial = [
  'facebook',
  'facebook-color',
  'facebook-negative',
  'linkedin',
  'linkedin-color',
  'linkedin-negative',
  'twitter',
  'twitter-color',
  'twitter-negative',
] as const;
export type TEcIconSocialType = (typeof ecIconsSocial)[number];
