import { forwardRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { Button, Icon } from '../../_shared';
import { shouldOpenInNewTab } from '../../_utils/linkHelpers';

import './menu.scss';

export type TRoute = { label: string; link: string };

type TProps = {
  className?: string;
  isMenuOpen: boolean;
  routes: TRoute[];
  toggleMenu: (nextValue?: boolean) => void;
};

export const Menu = forwardRef<HTMLElement, TProps>(({ isMenuOpen, toggleMenu, routes, className = '' }, ref) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <nav aria-expanded={isMenuOpen} className={`ecl-menu ${className}`} id="nav-menu" ref={ref}>
      <div className="ecl-menu__overlay" />
      <div className="ecl-container ecl-menu__container">
        <button className="ecl-button ecl-button--ghost ecl-menu__open" onClick={() => toggleMenu()}>
          <Icon name="hamburger" size="s" />
          {t('SHARED.NAVIGATION.MENU')}
        </button>
        <section aria-hidden={!isMenuOpen} className="ecl-menu__inner">
          <header className="ecl-menu__inner-header">
            <Button
              className="ecl-menu__close"
              containerClassName="ecl-menu__close-container"
              icon="close-filled"
              iconPosition="before"
              iconSize="s"
              onClick={() => toggleMenu(false)}
              theme="text"
            >
              {t('SHARED.NAVIGATION.CLOSE')}
            </Button>
            <div className="ecl-menu__title"> {t('SHARED.NAVIGATION.MENU')}</div>
          </header>
          <ul className="ecl-menu__list">
            {routes.map(({ link, label }) => (
              <li
                className={classNames('ecl-menu__item', {
                  'ecl-menu__item--current': pathname.includes(link),
                })}
                key={link}
              >
                {shouldOpenInNewTab(link) ? (
                  <a className="ecl-link ecl-link--standalone ecl-menu__link" href={link} rel="noreferrer" target="_blank">
                    {label}
                  </a>
                ) : (
                  <NavLink
                    className={classNames('ecl-link ecl-link--standalone ecl-menu__link', {
                      'ecl-menu__link--current': pathname.includes(link),
                    })}
                    to={link}
                  >
                    {label}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </section>
      </div>
    </nav>
  );
});
