import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDebounce } from '../../_hooks';
import Button from '../button/Button';

import './searchField.scss';

type TProps = {
  handleFocus?: () => void;
  label?: string;
  onSearch: (value: string) => void;
  placeholder?: string;
};

const SearchField: FC<TProps> = ({ onSearch, label, handleFocus, placeholder }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 1000);

  useEffect(() => {
    onSearch(debouncedValue);
  }, [debouncedValue]);

  const submitHandler = event => {
    event.preventDefault();
    onSearch(value);
  };

  return (
    <form className="ecl-search-form search-form" onSubmit={submitHandler} role="search">
      {label && (
        <label className="ecl-form-label " htmlFor="search-input">
          {label}
        </label>
      )}
      <div className="ecl-site-header__search">
        <div className="ecl-form-group">
          <input
            className="ecl-text-input ecl-text-input--m ecl-search-form__text-input"
            id="search-input"
            onChange={({ target: { value } }) => setValue(value)}
            onFocus={handleFocus}
            placeholder={placeholder}
            type="search"
            value={value}
          />
        </div>
        <Button className="ecl-search-form__button" icon="search" iconPosition="before" iconSize="xs" theme="ghost">
          {t('SHARED.BUTTONS.SEARCH')}
        </Button>
      </div>
    </form>
  );
};

export default SearchField;
