import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Config } from '../../config';
import Icon from '../icon/Icon';
import './socials.scss';
import IconSocial from '../iconsocial/IconSocial';

const Socials: FC = () => {
  const { t } = useTranslation();
  const socialMediaUrls = Config.socialMediaUrls(window.location.href);

  return (
    <ul className="ecl-social-media-share__list">
      <li className="ecl-social-media-share__item">
        <a
          className="ecl-link ecl-link--standalone ecl-link--icon ecl-social-media-share__link"
          href={socialMediaUrls.twitter}
          rel="noreferrer"
          target="_blank"
        >
          <IconSocial className="ecl-icon--m ecl-link__icon ecl-social-media-share__icon" name="twitter-color" />
          <span className="ecl-link__label">{t('SHARED.X')}</span>
        </a>
      </li>
      <li className="ecl-social-media-share__item">
        <a
          className="ecl-link ecl-link--standalone ecl-link--icon ecl-social-media-share__link"
          href={socialMediaUrls.facebook}
          rel="noreferrer"
          target="_blank"
        >
          <IconSocial className="ecl-icon--m ecl-link__icon ecl-social-media-share__icon" name="facebook-color" />
          <span className="ecl-link__label">{t('SHARED.FACEBOOK')}</span>
        </a>
      </li>
      <li className="ecl-social-media-share__item">
        <a
          className="ecl-link ecl-link--standalone ecl-link--icon ecl-social-media-share__link"
          href={socialMediaUrls.linkedin}
          rel="noreferrer"
          target="_blank"
        >
          <IconSocial className="ecl-icon--m ecl-link__icon ecl-social-media-share__icon" name="linkedin-color" />
          <span className="ecl-link__label">{t('SHARED.LINKEDIN')}</span>
        </a>
      </li>
      <button
        className="socials__copy ecl-social-media-share__item ecl-social-media-share__link"
        onClick={() => navigator.clipboard.writeText(window.location.href)}
      >
        <Icon className="ecl-button--text ecl-link__icon" name="copy" />
        <span className="ecl-link__label">{t('SHARED.COPY_LINK')}</span>
      </button>
    </ul>
  );
};
export default Socials;
